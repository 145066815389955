/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import deps from 'dependencies';
import { connect } from 'react-redux';
import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';
import EventsLink from 'appdir/components/general/EventsLink';
import Template from 'components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import AdUnitLazy from 'components/general/AdUnitLazy';
import Time from 'components/common-ui/Time';
import StubBox from 'appdir/components/common-ui/StubBox';
import ReactHtmlParser from 'html-react-parser';

/**
 * -----------------------------------------------------------------------------
 * React Component: Home
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['Schedule'],
		stubs: state?.['Config']?.stubPages,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.Schedule.mount()),
});

class Schedule extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			ticketsUrl: 'https://www.ticketmaster.com/usopentennis?cid=TMSchedule_07122021_phos_chluso_ctusot_endcid',
		};

		this.currentDay = 0;
		this.loadedJson = false;

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Event Schedule',
		});
		//logger.log('[Schedule] constructor - props:%o', props);
	}

	componentDidMount() {
		this.props.mount();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.schedulePath && !this.loadedJson) {
			deps.services.SchedulePage.fetch(this.props.schedulePath)
				.then(result => {
					//logger.log('[Schedule] componentDidUpdate - result:%o', result);
					this.loadedJson = true;
					this.setState({
						scheduleData: result.tournament_schedule,
					});
				})
				.catch(error => {
					logger.error('[Schedule] componentDidUpdate error:%o', error);
					this.loadedJson = false;
				});
		}

		logger.log('[Schedule] componentDidUpdate - this:%o', this);
	}

	onDaySelect(id) {
		//logger.log('[Schedule] onDaySelect - id:%o', id);
		this.setState({
			selectedDay: id,
		});

		const currentState = this.state.active;
		this.setState({ active: !currentState });
	}

	getSelectedDay(data) {
		for (var i = 0; i < data.length; i++) {
			if (data[i].current == true) {
				this.currentDay = data[i].id;
				this.setState({
					selectedDay: this.currentDay,
				});
			}
		}
	}

	renderScheduleContent() {
		let qualsInfo = this.state.scheduleData.draws.qualifying;
		let mainInfo = this.state.scheduleData.draws.maindraw;
		let notesInfo = this.state.scheduleData.notes;
		return (
			<>
				<section className="page-content tourn-schedule">
					{!window.webview ? <PageSubMenu mode="Schedule" selected="Tournament Schedule" /> : null}
					<div className="displaySchedule">
						<div className="header">{qualsInfo.name}</div>

						<div className="background">
							<div className="title">
								<div className="qual-day-color">&nbsp;</div>
								<div className="sid">SESSION</div>
								<div className="gate">Gates</div>
								<div className="start">Play</div>
								<div className="tourn-schedule-event">Event</div>
							</div>

							{qualsInfo.dates.map(q => {
								logger.log('[Schedule] render qualsInfo:%o', q);
								var stimes = q.session[0].times[0];

								return (
									<div className="quals">
										<div className="qual-day-color">
											<div className="date">
												<Time epoch_sec={q.epoch} format="MMM" />
												<span>
													<Time epoch_sec={q.epoch} format="D" />
												</span>
												<div className="qual-date-day">
													<Time epoch_sec={q.epoch} format="ddd" />
												</div>
											</div>
										</div>
										<div className="session-info">
											<div className="sid">{q.session[0].session_id}</div>
											<div className="gate">{stimes.gate}</div>
											<div className="start">{stimes.start}</div>
											<div className="tourn-schedule-event">
												{stimes.events.map(se => {
													return (
														<>
															{se}
															<br />
														</>
													);
												})}
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
					<div className="displaySchedule">
						<div className="header">{mainInfo.name}</div>

						<div className="background">
							<div className="title">
								<div className="qual-day-color">&nbsp;</div>
								<div className="sid">SESSION</div>
								<div className="gate">Gates</div>
								<div className="start">Play</div>
								<div className="tourn-schedule-event">Event</div>
							</div>

							{mainInfo.dates.map(m => {
								logger.log('[Schedule] render mainInfo dates:%o', m);
								return (
									<div className="maindraw">
										<div className="main-day-color">
											<div className="date mobile">
												<Time epoch_sec={m.epoch} format="MMM" />
												<span>
													<Time epoch_sec={m.epoch} format="D" />
												</span>
												<Time epoch_sec={m.epoch} format="ddd" />
											</div>
											<div className="date desktop">
												<Time epoch_sec={m.epoch} format="MMMM" />
												<span>
													<Time epoch_sec={m.epoch} format="D" />
												</span>
												<Time epoch_sec={m.epoch} format="dddd" />
											</div>
										</div>
										<div className="session-info">
											<div className="content">
												{m.session.map(s => {
													logger.log('[Schedule] render mainInfo session:%o', s);
													return (
														<div className="session">
															<div className="sid">{s.session_id}</div>
															<div
																className="session-content-wrapper"
																style={{ width: '100%' }}>
																{s.times.map((t, i) => {
																	return this.renderMainDraw(t, s.times.length);
																})}
															</div>
														</div>
													);
												})}
											</div>
											{m.session[0].link.url ? (
												<div className="ticket-container">
													<EventsLink to={m.session[0].link.url}>
														<i className="icon-tickets" /> {m.session[0].link.text}
													</EventsLink>
												</div>
											) : null}
										</div>
									</div>
								);
							})}
						</div>
					</div>
					<div className="footer">
						{/* {ReactHtmlParser(attributes['description'])} */}
						<div className="notes left">{ReactHtmlParser(notesInfo.bottom_left)}</div>
						<div className="notes right">{ReactHtmlParser(notesInfo.bottom_right)}</div>
					</div>
					{!window.webview && this.props.adConfig && (
						<div id={`schedule-ad-3`} className="mip-ad-container schedule-ad" key={`ad-3`}>
							<AdUnitLazy data={{ adType: 'mip' }} />
						</div>
					)}
				</section>
			</>
		);
	}
	renderMainDraw(data, len) {
		logger.log('[Schedule] renderMainDraw - in - gate: %o, start: %o, events: %o', data, data.start, data.events);
		// for (let i = 0; i < data.events.length; i++) {
		logger.log(
			'[Schedule] renderMainDraw - for - gate: %o, start: %o, event:%o',
			data.gate,
			data.start,
			data.events
		);

		return (
			<div className="session-content" style={{ display: 'flex', width: '100%' }}>
				<div className="gate">{data.gate}</div>
				<div className="start">{data.start}</div>
				<div className="tourn-schedule-event">
					<ul>
						{data.events.map((event, i) => {
							return <li key={i}>{event}</li>;
						})}
					</ul>
				</div>
			</div>
		);
		// }
	}

	render() {
		logger.log('[Schedule] render scheduleData this:%o', this);

		let header_attributes = {
			headerType: 'schedule',
			title: this.props?.pageHeader?.title,
			metaTitle: this.props?.pageHeader?.title,
			metaDescription: this.props?.pageHeader?.description,
			metaDate: '',
			metaPlayers: '',
			canonicalLink: 'https://www.usopen.org/en_US/about/eventschedule.html'
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			sponsor: {
				link: 'http://www.rolex.com',
				title: 'Rolex',
				name: 'Rolex',
				imgClass: '',
				imgSrc: '/assets/images/logos/rolex_logo_sm.png',
				tagline: 'In partnership with',
			},
			title: this.props?.pageHeader?.section,
			page_header: this.props?.pageHeader?.headerText,
		};

		if (this.props?.stubs && this.props?.stubs?.eventschedule?.stub === 'stub') {
			return (
				<Template header={header_attributes} subHeader={subheader_attributes} hideAd={true}>
					<section className="wrapper scorespage">
						<StubBox
							attributes={{
								title: header_attributes.title,
								message: this.props.stubs.eventschedule.text,
								basePicPath: this.props.basePicPath,
							}}
						/>
					</section>
				</Template>
			);
		} else {
			if (this.loadedJson && this.state.scheduleData) {
				return !window.webview ? (
					<Template header={header_attributes} subHeader={subheader_attributes}>
						{this.renderScheduleContent()}
					</Template>
				) : (
					this.renderScheduleContent()
				);
			} else {
				return (
					<Template>
						<section className="page-content tourn-schedule">
							<LoadingIndicator />
						</section>
					</Template>
				);
			}
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
