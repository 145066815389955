/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { connect } from 'react-redux';
import deps from 'dependencies';
import { getComponents } from 'appdir/components/general/Util';
import React, { Component } from 'react';
import JSXParser from 'react-jsx-parser';
import { parseString } from 'xml2js';
import endsWith from 'lodash/endsWith';
import MeasurementUtils from 'appdir/lib/analytics';
import StubBox from 'appdir/components/common-ui/StubBox';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Helmet from 'react-helmet';
import Template from 'components/Template';
import Time from 'appdir/components/common-ui/Time';
import ShareMenu from 'appdir/components/common-ui/ShareMenu';
import AgeGate from 'appdir/components/general/AgeGate';
import ErrorBoundary from 'components/general/ErrorBoundary';
import NotFound from 'appdir/components/NotFound';
import VideoWrapper from 'appdir/components/general/VideoWrapper';
import { cmsSubNavContentIdList } from 'appdir/components/common-ui/PageSubMenu/subNavLookUps';
import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';
import { fetch } from 'appdir/components/general/Util';
import { getAttributesArray, getComponentListArray } from 'appdir/components/pages/ContentPage/Utils';

/**
 * -----------------------------------------------------------------------------
 * React Component: Content Page
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['GeneralContentPage'],
		AgeGate: state['AgeGate'],
		Router: state['Router'],
		stubs: state['Config'].stubPages,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.GeneralContentPage.mount()),
	unmount: () => dispatch(deps.actions.GeneralContentPage.unmount()),
	updateViewedContent: (time, id) => dispatch(deps.actions.Controller.updateViewedContent(time, id)),
});
class GeneralContentPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageDate: {},
			accordionState: {},
		};
		this.onShareClick = this.onShareClick.bind(this);
		this.lastDataPath = '';
		this.toggleAccordion = this.toggleAccordion.bind(this);
		this.firstClick = true;

		logger.log('[GeneralContentPage] constructor this:%o', this);
	}

	componentDidMount() {
		// logger.log('[GeneralContentPage] componentDidMount');
	}

	componentWillUnmount() {
		// logger.log('[GeneralContentPage] componentWillUnmount');
		this.props.unmount();
	}

	setViewedContent(timestamp, contentId) {
		//logger.log('[GeneralContentPage] setViewedContent - timestamp:%o contentId:%o', timestamp, contentId);
		this.props.updateViewedContent(timestamp, contentId);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.dataUrl != undefined && endsWith(this.props.dataUrl, '.xml')) {
			//logger.log('[GeneralContentPage] componentDidUpdate - fetch:%o', this.props.dataUrl);

			//if path samem but key diff (new nav to existing location?)
			//  reload the data
			if (
				this.props.location.pathname == prevProps.location.pathname &&
				this.props.location.key != prevProps.location.key
			) {
				this.lastDataPath = '';
			}

			// if (
			//   this.props.Router.pathname == prevProps.Router.pathname &&
			//   this.props.Router.key != prevProps.Router.key
			// ) {
			//   logger.log(
			//     "[GeneralContentPage] componentDidUpdate - clear this.lastDataPath"
			//   );
			//   this.lastDataPath = "";
			// }

			if (this.props.dataUrl != this.lastDataPath) {
				this.lastDataPath = this.props.dataUrl;
				fetch(this.props.dataUrl)
					.then(result => {
						// logger.log('[GeneralContentPage] componentDidUpdate result:%o', result);

						//set the xml parse options
						let options = {
							explicitArray: false,
							normalize: true,
							trim: true,
							mergeAttrs: true,
							valueProcessors: [],
						};

						//parse the xml
						parseString(result, options, function(err, json) {
							result = json.contentItem;
						});

						//clean the jsx block
						result.jsx = result.jsx
							.replace(/data="{(.*?)}"/gi, 'data={$1}')
							.replace(/(<img("[^"]*"|[^\/">])*)>/gi, '$1/>')
							.replace(/\/p\/>/, '/p>');
						// clean the accordion blocks if present
						if (result.type === 'accordion') {
							result.section.map((item, i) => {
								result.section[i].jsx = item.jsx
									.replace(/data="{(.*?)}"/gi, 'data={$1}')
									.replace(/(<img("[^"]*"|[^\/">])*)>/gi, '$1/>');
							});
						}

						// logger.log('[GeneralContentPage] - result:%o', result);

						//update viewed content if has a date
						if (result.date) {
							this.setViewedContent(parseInt(result.date), result.contentId);
						}

						this.setState({
							data: result,
							pageData: result,
							subNavData: cmsSubNavContentIdList(result.contentId, this.props?.location?.pathname),
						});

						/** special metrics request - remove the page view metrics call for Amex Onsite Benefit page on iOS */
						if (result.contentId === 'american_express_on_site_benefits') {
							if (window.webviewPlatform !== 'ios') {
								MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
									content: result,
								});
							}
						} else {
							MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
								content: result,
							});
						}
					})
					.catch(error => {
						logger.error('[GeneralContentPage] componentDidUpdate error:%o', error);
						if (error.status == 404) {
							this.setState({
								data: 'notfound',
							});
						}
					});

				if (this.props.prevNextUrl != '' && this.props.prevNextUrl !== undefined) {
					fetch(this.props.prevNextUrl)
						.then(result => {
							logger.log('[GeneralContentPage] componentDidUpdate preNextUrl result:%o', result);

							this.setState({
								prevNextData: result,
							});
						})
						.catch(error => {
							logger.error('[GeneralContentPage] componentDidUpdate error:%o', error);
							if (error.status == 404) {
								this.setState({
									prevNextData: 'notfound',
								});
							}
						});
				}
			}
		} else {
			logger.warn('[GeneralContentPage] componentDidUpdate - improper data url: %o', this.props.dataUrl);
		}

		//this.handleColumns();
	}

	onShareClick(url) {
		//logger.log('[GeneralContentPage] onShareClick');
		MeasurementUtils.dispatchMeasurementCall('toggleShare', {
			display: url,
		});
		this.setState({
			sharePopup: 'hide',
		});
	}

	toggleShare() {
		//logger.log('[GeneralContentPage] toggleShare - sharePopup:%o', this.props.sharePopup);
		MeasurementUtils.dispatchMeasurementCall('toggleShare', {
			display: !this.props.sharePopup,
		});
		this.setState({
			sharePopup: this.props.sharePopup == 'hide' ? 'show' : 'hide',
		});
	}

	toggleAccordion(uuid) {
		let accordionState = this.state.accordionState;
		if (this.firstClick && uuid === '0') {
			accordionState[uuid] = {
				...uuid,
				expanded: false,
			};
			this.firstClick = false;
		} else {
			accordionState[uuid] = {
				...uuid,
				expanded: this.state.accordionState[uuid] ? !this.state.accordionState[uuid].expanded : true,
			};
		}

		// logger.log('[GeneralContentPage] toggleAccordion - accordionState after:%o', accordionState);
		this.setState({
			accordionState,
		});

		MeasurementUtils.dispatchMeasurementCall('toggleAccordion', {
			...accordionState[uuid],
		});
	}

	renderYearsMenu() {
		// logger.log('[GeneralContentPage] renderYearsMenu - pageData:%o', this.state.pageData.dependencies);
		let yearOptions = [];
		const years = [].concat(this.state.pageData.dependencies.data).map((item, i) => {
			if ((item.type = 'YearByYear')) {
				yearOptions.push(item.title);
			}
		});
		return (
			<select
				name="dropdown"
				size="1"
				id="select-anchor"
				className="champs-select-menu-dropdown"
				onChange={this.handleYearChange}>
				{yearOptions
					.sort((a, b) => b - a)
					.map((item, i) => (
						<option key={i} value={item}>
							{item}
						</option>
					))}
			</select>
		);
		// return years;
	}

	handleYearChange = e => {
		// logger.log("[GeneralContentPage] handleYearChange - event:%o", e.target.value);
		let yrElement = document.querySelector(`#_${e.target.value}`).getBoundingClientRect();
		// logger.log("[GeneralContentPage] handleYearChange - element:%o", yrElement);
		window.scrollTo(0, yrElement.top - 90);
	};

	getByline(name, url) {
		return (
			<div className={`four-col byline ${this.props.data.margin == 'true' ? 'left' : ''}`}>
				<div className="byline--attribution">{name ? 'By ' + name : ''}</div>
				<div className="byline--share">
					<div
						className="byline--share-button"
						onClick={() => {
							this.toggleShare();
						}}>
						<i className="icon-share" />
						SOCIAL
					</div>
					<div className={`byline--share-wrapper ${url ? 'threecol' : ''}`}>
						<ShareMenu
							type="popup"
							view={this.props.sharePopup}
							orient="top"
							onClick={this.onShareClick(url)}
							share={url}
						/>
					</div>
				</div>
			</div>
		);
	}

	getBreadcrumbs() {
		// logger.log("[GeneralContentPage] getBreadcrumbs - this:%o", this);
		let breadCrumbs = [
			{
				link: '/index.html',
				title: 'Home',
			},
		];
		if (this.state.pageData && this.state.pageData.breadcrumbs) {
			let breadCrumb = this.state.pageData.breadcrumbs;
			if (typeof breadCrumb !== 'undefined') {
				Object.keys(breadCrumb).map(function(k, i) {
					// logger.log("[GeneralContentPage] getBreadcrumbs - breadcrumb:%o", breadCrumb[k]);
					if (breadCrumb[k]?.title) {
						breadCrumbs.push(breadCrumb[k]);
					} else {
						//logger.log('[GeneralContentPage] getBreadcrumbs - removing: %o:', breadCrumb[k]);
					}
				});
			}
		}
		return breadCrumbs;
	}

	getSponsor() {
		// logger.log("[GeneralContentPage] getSponsor - this:%o", this);
		let sponsor = null;
		let { pageData } = this.state;

		if (pageData && pageData.sponsor) {
			if (pageData.sponsor.sponsor.name != 'nosponsor') {
				sponsor = {
					link: pageData.sponsor.sponsor.link,
					name: pageData.sponsor.sponsor.name,
					title: pageData.sponsor.sponsor.tagline,
					imgClass: pageData.sponsor.sponsor.imgClass,
					imgSrc: pageData.sponsor.sponsor.imgSrc,
					tagline: pageData.sponsor.sponsor.tagline,
				};
			}
		} else if (this.props.category != '' && this.props.sponsors[this.props.category]) {
			sponsor = this.props.sponsors[this.props.category];
		} else if (this.props.landing != '' && this.props.sponsors[this.props.landing]) {
			sponsor = this.props.sponsors[this.props.landing];
		}
		// logger.log("[GeneralContentPage] getSponsor - sponsor:%o", sponsor);
		return sponsor;
	}

	getHeader() {
		// logger.log("[GeneralContentPage] getHeader - this:%o", this);

		let { data } = this.state;

		let videoId = data.header && data.header.videoId && data.header.video ? data.header.videoId : null;

		let video_attributes = {
			playerId: 'main',
			contentId: videoId,
			videoUrl: null,
			title: null,
			thumb: null,
			autoplay: true,
			fullscreenMode: '',
			style: `video-wrapper one-col modal header`,
			aspect: 'wide',
			url: null,
		};

		//content page, (category)
		if (this.props.category != 'articles' && !this.props.landing) {
			let videoclass = videoId ? 'video' : '';

			return (
				<div className="news--header-wrapper">
					<div className={`news--header ${videoclass}`}>
						<div className="news--header-title">
							<h2>{data.title}</h2>
						</div>
						<div className="news--header-shorttitle">
							<h2>{data.shortTitle}</h2>
						</div>
						<div className="news--header-abstract">
							<h4>{data.abstract}</h4>
						</div>
					</div>
					{videoId ? (
						<div className="news--header-videoLink">
							<VideoWrapper attributes={video_attributes} type="header" />
						</div>
					) : null}
				</div>
			);
		} else if (this.props.landing && videoId) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header video" />
					<div className="news--header-videoLink">
						<VideoWrapper attributes={video_attributes} type="header" />
					</div>
				</div>
			);
		} else if (this.props.landing) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header" />
				</div>
			);
		}

		//article pages
		else if (videoId) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header video">
						<div className="news--header-icon">
							<i className="icon-news" />
						</div>
						<div className="news--header-date">
							<h3>
								<Time epoch_ms={data.date} format="ddd DD MMM YYYY kk:mm z" options="upper" />
							</h3>
						</div>
						<div className="news--header-title">
							<h2>{data.title}</h2>
						</div>
						<div className="news--header-shorttitle">
							<h2>{data.shortTitle}</h2>
						</div>
						<div className="news--header-abstract">
							<h4>{data.abstract}</h4>
							<h3> READ MORE</h3>
						</div>
					</div>
					<div className="news--header-videoLink">
						<VideoWrapper attributes={video_attributes} type="header" />
					</div>
				</div>
			);
		} else if (data.header.image && data.header.image.length > 25) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header">
						<div className="news--header-icon">
							<i className="icon-news" />
						</div>
						<div className="news--header-date">
							<h3>
								<Time epoch_ms={data.date} format="ddd DD MMM YYYY kk:mm z" options="upper" />
							</h3>
						</div>
						<div className="news--header-title">
							<h2>{data.title}</h2>
						</div>
						<div className="news--header-shorttitle">
							<h2>{data.shortTitle}</h2>
						</div>
						<div className="news--header-abstract">
							<h4>{data.abstract}</h4>
							<h3> READ MORE</h3>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	playFullVideo(id) {
		// logger.log('[GeneralContentPage] playFullVideo - id:%o', id);
	}

	renderAccordionJSX(jsx) {
		let attributes = getAttributesArray(this.state.pageData.dependencies.data);
		let components = getComponents(getComponentListArray(this.state.pageData.dependencies.data));
		// logger.log('[GeneralContentPage] renderAccordionJSX - attributes:%o components:%o:', attributes, components);
		// logger.log('[GeneralContentPage] renderAccordionJSX - data:%o:', this.state.pageData);

		<ErrorBoundary message="">
			<JSXParser
				bindings={attributes}
				components={{ ...components }}
				jsx={jsx}
				renderInWrapper={false}
				showWarnings={true}
			/>
		</ErrorBoundary>;
	}

	render() {
		logger.log('[GeneralContentPage] render - this:%o', this);

		//hide ads on ticket pages
		let hideAd = false;
		let { pageData } = this.state;

		if (
			this.props.Router &&
			this.props.Router.pathname &&
			(this.props.Router.pathname.includes('tickets') ||
				this.props.Router.pathname.includes('american_express_on_site_benefits'))
		) {
			hideAd = true;
		}

		if (this.state?.data === 'notfound') {
			logger.log('[GeneralContentPage] render - notfound:%o', this.props);

			return <NotFound />;
		} else if (
			pageData &&
			pageData.contentId &&
			this.props.prefix === undefined &&
			this.props.stubs &&
			this.props.stubs[pageData.contentId] &&
			this.props.stubs[pageData.contentId].stub === 'stub'
		) {
			let header_propsData = {
				headerType: this.props.category || this.props.landing,
				shortTitle: pageData.shortTitle,
				scrollElem: '.content-main',
				metaTitle: pageData.metaTitle,
				metaDescription: pageData.abstract,
				metaDate: pageData.date,
				scroll: false,
				shareUrl: pageData.url.share,
			};

			let subheader_attributes = {
				breadcrumbs: this.getBreadcrumbs(),
				title: pageData.title,
				sponsor: this.getSponsor(),
				page_header: pageData.title,
			};

			return (
				<Template hideAd={hideAd} header={header_propsData} subHeader={subheader_attributes}>
					<StubBox
						attributes={{
							title: header_propsData.metaTitle,
							message: this.props.stubs[pageData.contentId].text,
							basePicPath: this.props.basePicPath,
						}}
					/>
				</Template>
			);
		} else if (pageData && pageData.type === 'around ny' && !this.props.AgeGate.allowed) {
			let header_propsData = {
				headerType: 'generic',
				title: 'Around NY',
				metaTitle: 'Around NY.',
				metaDescription: '',
				metaDate: '',
				metaPlayers: '',
				imageSrc: '',
			};

			let subheader_attributes = {
				breadcrumbs: this.getBreadcrumbs(),
				title: 'Around NY',
				sponsor: this.getSponsor(),
				page_header: 'Around NY',
			};
			logger.log('[GeneralContentPage] render - around ny:%o', this.props);

			return (
				<Template hideAd={hideAd} header={header_propsData} subHeader={subheader_attributes}>
					<AgeGate />
				</Template>
			);
		} else if (pageData && pageData.type === 'accordion') {
			let attributes = getAttributesArray(pageData.dependencies.data);
			let components = getComponents(getComponentListArray(pageData.dependencies.data));
			logger.log('[GeneralContentPage] render - accordion attributes:%o components:%o:', attributes, components);
			//logger.log('[GeneralContentPage] render - data:%o:', pageData);

			let flex = pageData.tiled && pageData.tiled == 'true' ? 'flex' : '';

			let header_propsData = {
				headerType: this.props.category || this.props.landing,
				shortTitle: pageData.shortTitle,
				scrollElem: '.content-main',
				metaTitle: pageData.metaTitle,
				metaDescription: pageData.abstract,
				metaDate: pageData.date,
				scroll: false,
				shareUrl: pageData.url.share,
				canonicalLink: pageData.url.canonical,
			};

			let subheader_attributes = {
				breadcrumbs: this.getBreadcrumbs(),
				title: pageData.title,
				sponsor: this.getSponsor(),
				page_header: pageData.title,
			};

			//initially was checking for seemingly valid url to image or video due to invalid test data
			if (pageData.header.image && pageData.header.image.length > 25) {
				header_propsData['imageSrc'] = pageData.header.image;
				header_propsData['titleElem'] = '.news--header';
				header_propsData['scroll'] = true;
			}

			if (pageData.header.video && pageData.header.video.length > 25) {
				header_propsData['videoSrc'] = pageData.header.video;
			}

			let prevNextBackData = {
				prevNextData: this.props.prevNextData,
				category: this.props.category,
				history: this.props.history,
			};

			// logger.log(
			// 	'[GeneralContentPage] render - accordion propsData:%o, pageData:%o, state: %o, firstAccordionItem.id:%o',
			// 	header_propsData,
			// 	pageData,
			// 	this.state
			// );

			return (
				<Template hideAd={hideAd} header={header_propsData} subHeader={subheader_attributes}>
					<section
						className={`wrapper article-wrapper ${pageData?.hero === 'true' ? 'page-header-hero' : ''} 
							${pageData?.subnav?.section ? ` ${pageData?.subnav?.section}-page` : ''}
							${pageData?.contentId ? ` ${pageData?.contentId}` : ''}`}>
						<Helmet>
							{this.props.category == 'players_only' ? <meta name="robots" content="noindex" /> : null}
							<meta name="measureTitle" content="Detail" />

							<meta property="og:title" content={pageData.title} />
							<meta property="og:description" content={pageData.abstract} />
							<meta property="fb:app_id" content="471867639491882" />
							<meta property="og:image" content={pageData.header.image} />
							<meta property="og:url" content={pageData.url.share} />

							<meta name="twitter:card" content="summary_large_image" />
							<meta name="twitter:site" content="@USOpen" />
							<meta name="twitter:creator" content="@USOpen" />
							<meta name="twitter:title" content={pageData.title} />
							<meta name="twitter:description" content={pageData.abstract} />
							<meta name="twitter:image" content={pageData.header.image} />
							<meta name="twitter:url" content={pageData.url.share} />
							{pageData.url.canonical ? <link rel="canonical" href={pageData.url.canonical} /> : null}
							{pageData.url.amp ? <link rel="amphtml" href={pageData.url.amp} /> : null}
						</Helmet>
						{!pageData.hero || pageData.hero === 'false' ? (
							<h1 className="header">{pageData.title}</h1>
						) : null}
						<div className={`general_content accordion ${pageData?.contentId}`}>
							{pageData.hero === 'true' ? (
								<div className="page-header-hero-wrapper">
									<div
										className={`mobile-container-wrapper ${
											pageData?.gradient === 'false' ? 'hide' : ''
										}`}></div>
									<div
										className={`hero-container-wrapper ${
											pageData?.gradient === 'false' ? 'hide' : ''
										}`}></div>
								</div>
							) : null}
							<ErrorBoundary message="">
								<JSXParser
									bindings={attributes}
									components={{ ...components }}
									jsx={pageData.jsx}
									renderInWrapper={false}
									showWarnings={true}
									id={pageData?.contentId}
								/>
							</ErrorBoundary>

							<Accordion
								preExpanded={'0'}
								className="accordion-container"
								onChange={itemUuids => {
									this.toggleAccordion(itemUuids);
								}}>
								{pageData.section.map((item, i) => {
									let idReplace = item.id.replace('-', '');
									let expandClass;
									if (i == 0 && this.firstClick) {
										expandClass = 'icon-up-arrow';
									} else {
										expandClass = this.state.accordionState[i]?.expanded
											? 'icon-up-arrow'
											: 'icon-down-arrow';
									}
									// logger.log('[GeneralContentPage] render - expandClass:%o:', expandClass);
									return (
										<AccordionItem
											key={idReplace + i}
											uuid={i}
											dangerouslySetExpanded={this?.state?.accordionState[i]?.expanded}>
											<AccordionItemHeading
												className={`accordion-header ${
													this?.state?.accordionState[i]?.expanded ? 'expand' : ''
												}`}>
												<AccordionItemButton>
													<div className="ui-icon" role="presentation">
														<i className={expandClass}></i>
													</div>
													{item.title}
												</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel className="accordion-cnt">
												<ErrorBoundary message="">
													<JSXParser
														bindings={attributes}
														components={{ ...components }}
														jsx={item.jsx}
														renderInWrapper={false}
														showWarnings={true}
													/>
												</ErrorBoundary>
											</AccordionItemPanel>
										</AccordionItem>
									);
								})}
							</Accordion>
						</div>
					</section>
				</Template>
			);
		} else if (pageData) {
			let attributes = getAttributesArray(
				pageData.dependencies.data,
				pageData?.contentId,
				pageData?.subnav?.section,
				this.props?.match?.params?.tabName
			);
			let components = getComponents(getComponentListArray(pageData.dependencies.data));
			logger.log('[GeneralContentPage] render - attributes:%o components:%o:', attributes, components);
			logger.log('[GeneralContentPage] render - pageData:%o:', pageData);

			let flex = pageData.tiled && pageData.tiled == 'true' ? 'flex' : '';

			let header_propsData = {
				headerType: this.props.category || this.props.landing,
				shortTitle: pageData.shortTitle,
				scrollElem: '.content-main',
				metaTitle: pageData.metaTitle,
				metaDescription: pageData.abstract,
				metaDate: pageData.date,
				scroll: false,
				shareUrl: pageData.url.share,
				canonicalLink: pageData.url.canonical,
			};

			let subheader_attributes = {
				breadcrumbs: this.getBreadcrumbs(),
				title: pageData.title,
				sponsor: this.getSponsor(),
				page_header: pageData.title,
			};

			//initially was checking for seemingly valid url to image or video due to invalid test data
			if (pageData.header.image && pageData.header.image.length > 25) {
				header_propsData['imageSrc'] = pageData.header.image;
				header_propsData['titleElem'] = '.news--header';
				header_propsData['scroll'] = true;
			}

			if (pageData.header.video && pageData.header.video.length > 25) {
				header_propsData['videoSrc'] = pageData.header.video;
			}

			let prevNextBackData = {
				prevNextData: this.props.prevNextData,
				category: this.props.category,
				history: this.props.history,
			};

			let headerTitle = pageData.title;
			if (this.props.landing == 'sponsors') {
				headerTitle = 'Partners';
			}

			// logger.log('[GeneralContentPage] render - propsData:%o', header_propsData);

			return (
				// disable the ad, title bar, and breadcrumbs/logo for amex page
				<Template
					header={header_propsData}
					subHeader={pageData.type === 'amex' ? '' : subheader_attributes}
					hideAd={pageData.type === 'amex' ? true : false}>
					{pageData.contentId == 'be_open' ? (
						<ErrorBoundary message="">
							<PageSubMenu mode="News" selected="Be Open" />
						</ErrorBoundary>
					) : null}
					<section
						className={`wrapper article-wrapper 
							${pageData?.contentId?.includes('fan_week') ? 'fanweek' : ''} 
							${pageData?.type?.includes('page') ? 'page' : ''} 
							${pageData?.hero === 'true' ? 'page-header-hero' : ''} 
							${pageData?.subnav?.section ? ` ${pageData?.subnav?.section}-page` : ''}
							${pageData?.contentId ? ` ${pageData?.contentId}` : ''}
							${components?.TicketsWidget ? ' help-bar' : ''}`}>
						<Helmet>
							{this.props.category == 'players_only' ? <meta name="robots" content="noindex" /> : null}
							<meta name="measureTitle" content="Detail" />

							<meta property="og:title" content={pageData.title} />
							<meta property="og:description" content={pageData.abstract} />
							<meta property="fb:app_id" content="471867639491882" />
							<meta property="og:image" content={pageData.header.image} />
							<meta property="og:url" content={pageData.url.share} />

							<meta name="twitter:card" content="summary_large_image" />
							<meta name="twitter:site" content="@USOpen" />
							<meta name="twitter:creator" content="@USOpen" />
							<meta name="twitter:title" content={pageData.title} />
							<meta name="twitter:description" content={pageData.abstract} />
							<meta name="twitter:image" content={pageData.header.image} />
							<meta name="twitter:url" content={pageData.url.share} />

							{pageData.url.amp ? <link rel="amphtml" href={pageData.url.amp} /> : null}
						</Helmet>
						{/* {this.getHeader()} */}
						{/* {this.getByline(pageData.credit, pageData.url.share)} */}
						{!pageData.hero || pageData.hero === 'false' ? <h1 className="header">{headerTitle}</h1> : null}

						{/**  leavig this portion commenting this out in case we get more data in XML for selected parents besides mode
						 *   this method is ideal if there are no third menu (third menu doesn't come from CMS).
						 * 	This doesn't work because the third menu's shortTitle needs to be parent's selected menu,
						 *   e.g.)  mode: "Visit" -> Plan Your Visit -> Accessibility page, shortTitle is "Accessibility"
						 *          It needs to be "Plan Your Visit" to properly select which menu to select
						 *			and search the third nav menu from config
						 */
						/* pageData?.subnav?.show === 'true' || pageData?.subnav?.show ? (
								<ErrorBoundary message="">
									<PageSubMenu
										mode={pageData?.subnav?.section}
										selected={pageData?.shortTitle ? pageData?.shortTitle : ''}
										// filters={this.props.filters}
									/>
								</ErrorBoundary>
							) : null */}

						{
							//if XML doesn't flag subnav: true, check LookUps - if contentId exists, display subnav
							// !pageData?.subnav?.show ||
							// pageData?.subnav?.show == 'false' &&
						}
						{/**
						 * pass contentId to cmsSubNavContentIdList (LookUp Util)
						 * if there is an entry, place PageSubMenu
						 */}
						{this.state?.subNavData?.mode !== '' ? (
							<ErrorBoundary message="The page subnavigation is unavailable at this time.">
								<PageSubMenu
									mode={this.state.subNavData.mode}
									selected={this.state.subNavData.selected}
									type={this.state.subNavData.typeSel ? this.state.subNavData.typeSel : ''}
								/>
							</ErrorBoundary>
						) : pageData?.subnav?.section == 'fan-week' ? (
							<ErrorBoundary message="The page subnavigation is unavailable at this time.">
								<PageSubMenu
									mode="Fan Week"
									selected={this.state.subNavData.selected.replace('-', ' ')}
									type={this.state.subNavData.typeSel ? this.state.subNavData.typeSel : ''}
								/>
							</ErrorBoundary>
						) : null}

						<div
							className={`general_content ${pageData.type} ${
								this.props.landing === 'sponsors' ? 'page-sponsors-logo' : ''
							}`}>
							{pageData.hero === 'true' ? (
								<div className="page-header-hero-wrapper">
									<div
										className={`mobile-container-wrapper ${
											pageData?.gradient === 'false' ? 'hide' : ''
										}`}></div>
									<div
										className={`hero-container-wrapper ${
											pageData?.gradient === 'false' ? 'hide' : ''
										}`}></div>
								</div>
							) : null}
							{pageData.contentId == 'year_by_year' ? (
								<section className="champs-select-menu">{this.renderYearsMenu()}</section>
							) : null}
							<ErrorBoundary message="The page content is unavailable at this time.">
								<JSXParser
									bindings={attributes}
									components={{ ...components }}
									jsx={pageData.jsx}
									renderInWrapper={false}
									showWarnings={true}
								/>
							</ErrorBoundary>
						</div>
					</section>
				</Template>
			);
		} else {
			logger.warn('[GeneralContentPage] render - data not loaded, this:%o', this);

			let header_propsData = {
				headerType: 'generic',
				titleElem: '.news--header',
				scroll: false,
			};

			return (
				<Template hideAd={hideAd} header={header_propsData}>
					<div className="content-main">
						<LoadingIndicator />
					</div>
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralContentPage);
